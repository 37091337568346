<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(name)="{ item }">
            <router-link :to="'/custom-object/detail/' + item.id"
              ><u>{{ item.name }}</u>
            </router-link>
          </template>
          <template v-slot:cell(is_active)="{ item }">
            <!-- {{ item.is_active ? "Active" : "InActive" }} -->
            <div v-if="item.is_active" class="text-success">Active</div>
            <div v-else class="text-error">InActive</div>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <router-link :to="'/custom-object/detail/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
              <!-- <b-button
                variant="link"
                class="text-danger px-1 py-0"
                @click="$emit('deleteForm', item.id)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="Delete"
                  class="text-custom-primary"
                />
              </b-button> -->
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },

    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "is_active",
          label: "Status",
        },

        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  methods: {
    stringName(item) {
      const first_name = item.first_name || "";
      const last_name = item.last_name || "";

      return first_name + " " + last_name;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show1 = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.activity_type = [];
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    async exportFile(form) {
      this.isLoading = true;
      var url = `/transactioncode/code_report/${form.id}`;

      const data = await this.axios(url, {
        responseType: "blob",
      });
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${form.name}_Code_report.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    async deleteForm(id) {
      const resp = await this.axios.delete(`/CustomObject/delete_answer/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
